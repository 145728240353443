.root {
  background-color: var(--fill-base);
  border-radius: var(--border-radius-3);
  padding: 0 0 20px;
  min-width: 0;
  margin-bottom: 20px;
  position: relative;
}

.image {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  cursor: pointer;
}

.price {
  font-size: 24px;
  font-weight: bold;
}

.title {
  min-height: 48px;

  span {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word; /* Important for long words! */
  }

  color: var(--fill-medium-emphasis);
  font-size: 16px;
  margin: 0 0 15px 0;
  cursor: pointer;
}

.content {
  padding: 0 22px;
}

.footer {
  display: flex;
  align-items: center;
}

.favoriteIcon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
.addProductToCollection {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  .productCount {
    font-size: 24px;
  }
}

.deleteIcon {
  position: absolute;
  top: 8px;
  right: 45px;
  cursor: pointer;
}

.list {
  display: flex;
  align-items: center;

  .image {
    width: 200px;
  }
}

.btn {
  position: absolute;
  top: 40%;
  left: 0;
  display: none;
  width: calc(100% - 60px);
  margin: 0 30px;
}

.imageWrapper {
  padding: 30px 20px 20px 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 270px;

  &:hover {
    .btn {
      display: block;
    }
  }
}

.marketPlacesLogo {
  padding: 8px;

  img {
    width: 30px;
    margin: 0 5px;
  }

  height: 30px;
}

.viewButton {
  width: 100%;
}

.viewButton2 {
  width: 100%;
  background-color: var(--fill-medium-emphasis);
}

.productLink:hover {
  color: currentColor;
}

.amount {
  font-size: 18px;
  font-weight: bold;
}
