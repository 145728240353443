:root {
  --multiplier: 1;
  --app-height: 100%;

  // Transitions
  --hover-transition: 200ms;
  --hover-opacity: 0.7;

  // Border radius
  --border-radius-1: 5px;
  --border-radius-2: 15px;
  --border-radius-3: 20px;

  // Fonts
  --font-primary: "Lato", sans-serif;
  --fs-homePage-xl: 96px;
  --fs-homePage-lg: 38px;

  // Header height
  --header-height: 0;

  color: var(--neutral-white);

  --neutral-platinum: rgb(var(--neutral-platinum-code));
  --neutral-platinum-code: 235, 235, 235;

  --neutral-black: rgb(var(--neutral-black-code));
  --neutral-black-code: 255, 255, 255;

  --neutral-grey: rgb(var(--neutral-grey-code));
  --neutral-grey-code: 102, 102, 102;

  --neutral-light-grey: rgb(var(--neutral-light-grey-code));
  --neutral-light-grey-code: 133, 133, 133;

  --neutral-baby-power: rgb(var(--neutral-baby-power-code));
  --neutral-baby-power-code: 251, 251, 251;

  --pastel-alice-blue: rgb(var(--pastel-alice-blue-code));
  --pastel-alice-blue-code: 237, 244, 255;

  --neutral-disabled: rgb(var(--neutral-disabled-code));
  --neutral-disabled-code: 204, 204, 204;

  --neutral-hover: rgb(var(--neutral-hover-code));
  --neutral-hover-code: 241, 248, 252;

  --fill-primary: rgb(var(--fill-primary-code));
  //--fill-primary-core: #8dc644;
  --fill-primary-core: #f79633;
  //--fill-primary-code: 141, 198, 68;
  --fill-primary-code: 247, 150, 51;

  --fill-secondary: rgb(var(--fill-secondary-code));
  --fill-secondary-core: #3b4859;
  --fill-secondary-code: 59, 72, 89;

  --fill-low-emphasis: rgb(var(--fill-low-emphasis-code));
  --fill-low-emphasis-code: 59, 72, 89;

  --fill-border: rgb(var(--fill-border-code));
  --fill-border-code: 223, 229, 233;

  --fill-high-emphasis: rgb(var(--fill-high-emphasis-code));
  --fill-high-emphasis-core: #eff2f6;
  --fill-high-emphasis-code: 239, 242, 246;

  --fill-base: rgb(var(--fill-base-code));
  --fill-base-core: #ffffff;
  --fill-base-code: 255, 255, 255;

  --fill-base-light: rgb(var(--fill-base-light-code));
  --fill-base-light-core: #f5f7fa;
  --fill-base-light-code: 245, 247, 250;

  --fill-medium-emphasis: rgb(var(--fill-medium-emphasis-code));
  --fill-medium-core: #9da3ac;
  --fill-medium-emphasis-code: 157, 163, 172;

  --fill-links: rgb(var(--fill-links-code));
  --fill-links-code: 20, 107, 189;

  --fill-success: rgb(var(--fill-success-code));
  --fill-success-core: #72ceab;
  --fill-success-core-dark: #026845;
  --fill-success-code: 3, 174, 115;

  --fill-error: rgb(var(--fill-error-code));
  --fill-error-core: #f96186;
  --fill-error-code: 249, 97, 134;

  --fill-disabled: #dfe5e9;
  --fill-disabled-core: #dfe5e9;

  --fill-error-text: rgb(var(--fill-error-text-code));
  --fill-error-text-core: #d3003d;
  --fill-error-text-code: 211, 0, 61;

  --fill-warning: rgb(var(--fill-warning-code));
  --fill-warning-core: rgb(169, 97, 48);
  --fill-warning-code: 223, 120, 51;

  --fill-warning-solid: rgb(141 79 39);

  --fill-border-light: rgb(var(--fill-border-light-code));
  --fill-border-light-code: 8, 8, 8;

  --fill-border-base: rgb(var(--fill-border-code));
  --fill-border-base-code: 255, 255, 255;

  // Spacing
  --spacing-xs: 5px;
  --spacing-s: 10px;
  --spacing-m: 20px;

  *::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
    background-color: transparent;
  }

  *::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: var(--fill-high-emphasis);
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--fill-medium-emphasis);
    cursor: pointer;
    border-radius: 2rem;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: var(--fill-medium-emphasis);
  }

  *::-webkit-scrollbar-corner {
    background-color: var(--fill-high-emphasis);
  }
}

@media (max-width: 1198px) {
  :root {
    --fs-homePage-xl: 72px;
    --fs-homePage-lg: 42px;
  }
}

@media (max-width: 600px) {
  :root {
    --fs-homePage-xl: 24px;
    --fs-homePage-lg: 14px;
  }
}
