.root {
  padding: 0 40px 40px;
  min-height: 100vh;

  td {
    vertical-align: middle;
  }
}

.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 15px;
}

.title2 {
  font-size: 28px;
  color: var(--fill-low-emphasis);
  margin-bottom: 30px;
  margin-top: 30px;
}

.wrapper {
  background-color: var(--fill-base);
  border-radius: var(--border-radius-2);
  padding: 30px;
  min-height: 40vh;
}

.content {
  padding: 40px 0;
}

.gallery {
  img {
    width: 100%;
  }
}

.breadcrumb {
  display: flex;
  justify-content: space-between;
}

.breadcrumbName {
  color: var(--fill-primary);
}

.categoryLink,
.warehouseLink {
  cursor: pointer;
  color: #1a0dab;
  text-decoration: underline;
}

.name {
  font-size: 22px;
  margin-bottom: 15px;
}

.price {
  font-size: 26px;
  color: var(--fill-primary);
  font-weight: bold;
}

.vendorCode {
  font-size: 22px;
  color: var(--fill-primary);
  margin-bottom: 15px;
  font-weight: bold;
  user-select: none;
}

.copyVendorCodeIcon {
  margin-bottom: 5px;
  margin-left: 10px;
  cursor: copy;
}

.item {
  margin-bottom: 5px;

  span {
    margin-left: 10px;
  }
}

.productImage {
  img {
    width: 100%;
    cursor: zoom-in;
  }

  border-radius: 8px;
  position: relative;
  padding: 35px 10px 10px 10px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px 2px #888888;
}

.productImageCol {
  padding: 0 5px;
}

.deleteImage {
  position: absolute;
  top: 8px;
  right: 8px;
}

.list {
  position: relative;
}

.heartIcon {
  padding: 1.2rem 0.8rem;
}

.tr {
  font-size: 18px;
}

.description {
  margin-top: 15px;
  font-size: 16px;
}

.filterHover {
  cursor: pointer;
  color: #1a0dab;
}

.filterHover:hover {
  text-decoration: underline;
}

.copyIcon {
  margin-left: 10px;
  cursor: copy;
}
.editIcon {
  cursor: pointer;
}

.addOzonProduct,
.addWildberriesProduct,
.addYandexProduct {
  color: white;
  margin: 10px 0 0 10px;
}

.addOzonProduct {
  background: #005bff;
}

.addOzonProduct:hover {
  background: #005bff !important;
  opacity: 0.8;
}

.addWildberriesProduct {
  background: #cb11ab;
}

.addWildberriesProduct:hover {
  background: #cb11ab !important;
  opacity: 0.8;
}

.addYandexProduct {
  background: #f5bc00;
}

.addYandexProduct:hover {
  background: #f5bc00 !important;
  opacity: 0.8;
}

.allButtons {
  margin-bottom: 10px;
  display: flex;
  text-align: end;
}

.favoriteIcon {
  cursor: pointer;
  margin-right: 10px;
}

.syncProductButton:hover {
  color: #f33;
}

.noCopyText {
  user-select: none;
}

.vendorCodeWarningText {
  font-size: 14px;
  color: #f33;

  svg {
    margin-right: 5px;
  }
}

.marketPlacesLogo {
  vertical-align: middle;
  padding: 8px;

  img {
    height: 30px;
    margin: 0 5px;
  }
}

.priceInputs {
  display: flex;

  div {
    margin: 0;
  }

  button {
    margin-left: 10px;
  }
}

.loadChangePrice {
  cursor: progress !important;
}

.addToCollectionButton,
.addToCollectionButtonLoad {
  padding: 10px 20px;
  margin-top: 180px;
}
.addToCollectionButtonLoad {
  cursor: progress !important;
}

.addToCollectionModalRoot {
  padding: 20px;
  overflow: hidden;
}
.addToCollectionSelect {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 100%;
  box-sizing: border-box;
}
.addProductToCollectionButton {
  padding: 5px 10px;
  margin-right: 10px;
}

.editCollectionDescModalRoot {
  padding: 20px;
  overflow: auto;
  width: 700px;
}
.collectionEditInput {
  border-radius: 20px;
  border: 1px solid #dee2e6;
  padding: 10px;
}
.disabledButton {
  button {
    background-color: grey !important;
  }
}
.ProductInfo {
  display: flex;
  align-items: center;
}
.warehouseName {
  color: #f49100;
  font-size: 20px;
  margin-right: 10px;
}

@media (max-width: 800px) {
  .root {
    padding: 0 10px 10px;
  }
}
