.delete {
  text-align: center;
  padding: 30px;
}

.text {
  padding-bottom: 20px;
  font-size: 20px;
}

.btns {
  margin: auto;
  width: 150px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
}
