.root {
  padding: 0 40px 40px;
  min-height: 100vh;

  td,
  th {
    vertical-align: middle;
  }
}

.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 15px;
}

.wrapper {
  min-width: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  background-color: var(--fill-base);
  border-radius: var(--border-radius-2);
  padding: 30px;
}

.thead {
  position: sticky;
}

.theadTh {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    margin-left: 10px;
  }

  svg {
    color: rgba(0, 0, 0, 0.5);
  }

  svg:hover {
    color: rgb(0, 0, 0);
  }
}

.MPLogos {
  text-align: center;
  width: 5rem;

  img {
    width: 80%;
  }
}

.packageLabel {
  text-align: center;
}

.packageLabelA {
  text-decoration: underline;
  color: #1a0dab;
  cursor: pointer;
}
.filters {
  display: flex;
  flex-wrap: wrap;
}

.searchInput {
  min-height: 49px !important;
  height: 49px;
  margin-right: 8px;
  margin-bottom: 8px !important;
}

.search {
  display: flex;
}

.normal {
  width: 150px !important;
  margin-right: 8px;
  border: unset !important;
  margin-bottom: 8px !important;

  :global {
    .react-select__control {
      min-height: 47px;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
}

.reset {
  color: var(--fill-primary);
  cursor: pointer;
}

.tag {
  border: 1px solid var(--fill-border);
  border-radius: var(--border-radius-3);
  padding: 5px 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background-color: var(--fill-base);
}

.label {
  margin-right: 10px;
}

.pagination {
  position: relative;
}
.small {
  width: 80px !important;
  position: absolute;
  top: 55px;

  :global {
    .react-select__control {
      min-width: 75px;
    }
  }
}
@media (max-width: 800px) {
  .root {
    padding: 0 10px 10px;
  }
  .small {
    display: none;
  }
}
