.replySection {
   height: auto;
   background: none;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   margin-bottom: 20px;
   .replySectionInput {
      border: 1px solid;
      border-radius: 10px;
      height: 50px;
      width: 400px;
      margin-left: 20px;
      margin-right: 10px;
      padding-left: 10px;
      padding-top: 10px;
   }
  
   .replySectionBtnAnswer {
      color: blue;
      margin-right: 10px;
   }
   .replySectionBtnCancle {
      color: #ff3333;
   }
}
