.root {
  padding: 0 40px 40px;
  min-height: 100vh;

  td,
  th {
    vertical-align: middle;
  }
}

.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 15px;
}

.wrapper {
  min-width: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  background-color: var(--fill-base);
  border-radius: var(--border-radius-2);
  padding: 30px;
}

.theadTh {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    margin-left: 10px;
  }
}

.small {
  width: 80px !important;
  position: absolute;
  top: 55px;

  :global {
    .react-select__control {
      min-width: 75px;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
}

.reset {
  color: var(--fill-primary);
  cursor: pointer;
}

.tag {
  border: 1px solid var(--fill-border);
  border-radius: var(--border-radius-3);
  padding: 5px 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background-color: var(--fill-base);
}

.label {
  margin-right: 10px;
}

.cardTitle {
  cursor: pointer;
  color: #1a0dab;
}

.cardTitle:hover {
  text-decoration: underline;
}

@media (max-width: 800px) {
  .root {
    padding: 0 10px 10px;
  }
  .small {
    display: none;
  }
}

.dates {
  div {
    margin-right: 10px;
    float: left;
  }

  font-size: 18px;
  margin-bottom: 10px;

  input {
    margin-left: 5px;
    border: none;
  }
}

.collationA {
  text-decoration: underline !important;
  color: #1a0dab !important;
}

.disabledLinks {
  color: var(--fill-medium-emphasis);
}

.pagination {
  position: relative;
}

.CollateModalRoot {
  height: 100%;
  padding: 20px;
  overflow: auto;
}

.collateButton,
.collateButtonLoad {
  padding: 10px 20px;
  margin-top: 20px;
}

.collateButtonLoad {
  cursor: progress !important;
}

.collationLinks {
  div {
    display: flex;
  }

  a,
  span {
    margin-right: 5px;
  }
}

.searchInput {
  min-height: 49px !important;
  height: 49px;
  margin-right: 8px;
  margin-bottom: 8px !important;
}

.search {
  display: flex;
}
