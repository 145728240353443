.questionsScroll {
   max-height: auto;
   overflow: scroll;
   overflow-x: hidden;
   .questionsContainer {
      width: 900px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 10px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      margin-top: 20px;

      .questionsBox {
         width: 95%;
         height: auto;
         padding: 10px;
         background-color: #f2f2f2;
         display: flex;
         gap: 25px;
         justify-content: space-around;
         align-items: center;
         border-radius: 10px !important;
         .questionsText {
            width: 60%;
            padding-left: 10px;
         }
         .questionsContainerBtns {
            width: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            .questionBtnView {
               color: #ffff;
               width: auto;
               height: auto;
               padding: 10px;
               background-color: #f79633;
               border-radius: 10px;
               cursor: pointer;
            }
            .questionBtnDelete {
               color: #ff3333;
               cursor: pointer;
            }
            .questionBtnReply {
               color: green;
               cursor: pointer;
            }
            p {
               font-size: 15px;
            }
         }
      }
   }
}

