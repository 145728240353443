.bettingSection {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   margin-top: 200px;
   h1 {
      font-size: 48px;
      color: #000;
   }
   p {
      font-size: 20px;
      margin-top: 20px;
      color: #000;
   }
   .bettingSectionText {
      font-size: 60px;
      text-align: center;
      color: #ffb801;
      margin-bottom: 60px;
      margin-top: 60px;
      font-weight: lighter;
      max-width: 70%;
   }
   .percentContainer {
      display: flex;
      justify-content: center;
      gap: 60px;
      padding-left: 30px;
      width: 70%;
      p {
         font-size: 17px;
         color: #000;
      }
      .percentContainerIntem {
         width: 33.33%;
         .percentContainerIntemNumber {
            font-size: 200px;
            color: #ffb801;
            font-weight: lighter;
            position: relative;
            z-index: -9;
            .percent {
               font-size: 96px;
               position: absolute;
               top: 12%;
               color: #595959;
            }
         }
      }
   }
}

@media (max-width: 1100px) {
   .bettingSection {
      .bettingSectionText {
         width: 80%;
      }
      .percentContainer {
         width: 80%;
      }
   }
}

@media (max-width: 780px) {
   .bettingSection {
      .bettingSectionText {
         width: 80%;
      }
      .percentContainer {
         flex-direction: column;
         justify-content: center;
         align-items: center;

         .percentContainerIntem {
            text-align: center;
            width: 100%;
         }
      }
   }
}
