.page {
  padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    .faqsBody{
     text-align: center;
    }
  }
  
  .title {
    font-size: 32px;
    color: var(--fill-low-emphasis);
    margin-bottom: 15px;
    text-transform: uppercase;
    text-align: center;
  }
  
  .addFaq {
    position: fixed;
    right: 50px;
    bottom: 50px;
    box-shadow: 5px 5px 10px #888888;
    height: 60px;
    margin-left: 10px;
    width: 120px;
    align-self: center;
    z-index: 9999;
    cursor: pointer;
  }
  
  .button:hover,
  .addFaq:hover {
    background-color: #9da3ac;
    cursor: pointer;
  }
  
  .button {
    height: 49px;
    margin-left: 10px;
    width: 100px;
    align-self: center;
    cursor: pointer;
  } 
  
  
