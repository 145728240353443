.pdp-slider {
  display: flex;
  width: 100%;
  max-height: 515px;
  justify-content: space-between;
  &__thumbs {
    width: 63px;
    .pdp-slider__thumb {
      margin: 10px 0;
    }
  }
  &__galleries {
    width: calc(100% - 85px);
    &:hover {
      img {
        // opacity: 0;
      }
    }
    img {
      width: 90%;
      // opacity: 1;
      // transition: all .3s;
    }
  }
  &__thumb {
    width: 60px;
    height: 65px;
    border: 1.4px solid #ccc;
    border-radius: 2px;
    transition: border-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &--active {
      border-color: #0099ff;
    }
    img {
      width: 90%;
    }
  }
}

.slick-arrow {
  background: transparent;
  border: 0;
  text-align: center;
}
