.notFoundDiv{
    text-align: center;
    padding-top: 40px;
    p{
        font-size: 16px;
        margin-top: 10px;
    }
    div{
        margin-top: 20px;
    }
    a{
        color: #f49100;
        font-size: 18px;
        
    }
    a:hover{
        color: #c70000;
    }
}