.root {
  padding: 0 40px 40px;

  th,
  td {
    vertical-align: middle;
  }
}

.copyIcon {
  margin-left: 10px;
  cursor: copy;
}

.editIcon {
  cursor: pointer;
}

.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 10px;
}

.titleMarketplace {
  font-size: 24px;
  color: var(--fill-low-emphasis);
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  color: var(--fill-low-emphasis);
  margin-bottom: 20px;
}

.wrapper {
  display: flex;
}

.content {
  width: 100%;
}

.icon {
  display: inline-block;
}

.row {
  border-radius: var(--border-radius-3);
  background-color: var(--fill-base);
  padding: 30px 25px 10px;
  margin-bottom: 10px;
}

.info {
  display: flex;
  width: 90%;
}

.select,
.input {
  margin-right: 20px;
  width: 33%;
}

.banner {
  width: 30%;
  border-radius: var(--border-radius-3);
  background-color: var(--fill-base);
  min-height: 75vh;
}

.modal {
  padding: 45px 90px;
  background-color: var(--fill-base);
  border-radius: var(--border-radius-2);

  .title {
    text-align: center;
    margin-bottom: 25px;
  }

  .select,
  .input {
    width: 100%;
  }
}

.delete {
  text-align: center;
  padding: 30px;
}

.text {
  padding-bottom: 20px;
}

.btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
}

.check {
  span {
    color: #1a0dab;
    cursor: pointer;
  }

  span:hover {
    text-decoration: underline;
  }
}

.checkRoot {
  display: flex;

  * {
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
}

.marketplaceInput {
  margin-bottom: 10px !important;
}

.marketplacesButton {
  margin-top: 30px;
}

.getTokenButton {
  height: 35px;
  margin-left: 16px;
}

@media (max-width: 800px) {
  .root {
    padding: 0 10px 10px;
  }
  .small {
    display: none;
  }
}

.marketplacesButtons {
  display: flex;

  button {
    margin-right: 10px;
    padding: 18px 10px;
    font-size: 16px;
    height: 55px;
    margin-bottom: 30px;
    margin-top: 10px;
  }
}
