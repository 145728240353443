.root {
  padding: 0 40px 40px;
  min-height: 100vh;
}
.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 15px;
}
.wrapper {
  min-width: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  background-color: var(--fill-base);
  border-radius: var(--border-radius-2);
  padding: 30px;
}
.financeUpload {
  .addButton {
    padding: 8px 16px;
  }
  .packageLabel {
    text-align: center;
    width: 38px;
  }

  .packageLabelA {
    cursor: pointer;
    color: #1a0dab;
  }
}
.financeDocument {
  padding: 5px;
  height: 100%;
}
.deleteIcon {
  cursor: pointer;
}
@media (max-width: 800px) {
  .root {
    padding: 0 10px 10px;
  }
}
