.root {
  position: relative;
  margin-bottom: 35px;
}

.borderLeft:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 1.1rem;
  width: 0.1rem;
  transform: translateY(-50%);
  background-color: var(--fill-low-emphasis);
  opacity: 0.4;
}

.label {
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 16px;
  color: var(--fill-low-emphasis);
  display: block;
  min-height: 33px;
}

.error {
  position: absolute;
  bottom: -25px;
  left: 5px;
  width: 100%;
  color: var(--fill-error-text);
  padding: 0.2rem;
  font-size: 1rem;
}


.errorInput {
  border: 1px solid var(--fill-error) !important;
}

.requiredSpan {
  color: var(--fill-error);
  font-size: 22px;
}
.react-select--is-disabled{
  background-color: red;
}