.convertedText {
   width: 100%;
   height: auto;
   padding: 20px;
   background-color: antiquewhite;
}
.btnSave {
   padding: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 0.15rem;
   font-weight: 500;
   line-height: 1.4em;
   white-space: nowrap;
   background-color: #f49100;
   border-radius: var(--border-radius-2);
   border: 0.1rem solid transparent;
   cursor: pointer;
   margin: 20px auto;
}

.draftEditor {
   height: 250px;
   line-height: 0.5px;
   padding-top: 10px;
   display: flex;
   flex-direction: column;
   justify-content: left;
   align-items: center;
}
.editorText {
   margin-top: 20px;
   background-color: bisque;
}

p {
   img {
      width: auto;
      height: auto;
   }
}
