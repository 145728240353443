.toggleSwitch {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
}
.toggleSwitchCheckbox {
  display: none;
}
.toggleSwitchLabel {
  display: block;
  overflow: hidden;
  border: 0 solid var(--fill-low-emphasis);
  border-radius: 2rem;
  margin: 0;
}
.toggleSwitchInner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  &:before,
  &:after {
    display: block;
    float: left;
    width: 50%;
    height: 3.5rem;
    padding: 0;
    line-height: 3.4rem;
    font-size: 1.4rem;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
  }
  &:before {
    content: "";
    text-transform: uppercase;
    padding-left: 1rem;
    background-color: var(--fill-primary);
    color: #fff;
  }
}

.toggleSwitchInner:after {
  content: "";
  text-transform: uppercase;
  padding-right: 1rem;
  background-color: var(--fill-error);
  color: #fff;
  text-align: right;
}
.toggleSwitchSwitch {
  display: block;
  width: 5.5rem;
  height: 3rem;
  margin: 0.25rem 0.2rem;
  background: var(--fill-base);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5.5rem;
  border: 0 solid var(--fill-low-emphasis);
  border-radius: 5rem;
  transition: all 0.3s ease-in 0s;
}
.toggleSwitchCheckbox:checked + .toggleSwitchLabel {
  .toggleSwitchInner {
    margin-left: 0;
  }
  .toggleSwitchSwitch {
    right: 0;
  }
}
.secondary {
  .toggleSwitchSwitch {
    background: var(--fill-base);
  }
}
.texts {
  position: absolute;
  display: flex;
  top: 9px;
  color: var(--fill-base);
  p {
    width: 5.8rem;
    text-align: center;
  }
}
.active {
  color: var(--fill-low-emphasis);
}
