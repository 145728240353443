.Logo {
  margin-left: -8px;
  margin-top: -15px;
}

.footer {
  background-color: #272726;
  padding: 130px 0 200px 0;
}

.footerContent {
  color: #fff;
  justify-content: space-between;
  display: flex;
  margin: 0 auto;
  font-size: 15px;

  @media (max-width: 768px) {
    flex-direction: column !important;
    width: fit-content;
    margin: auto;
    gap: 60px;
  }

  @media (max-width: 992px) {
    padding: 0 20px;
    max-width: 100%;
    column-gap: 20px;
  }

  .contact {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 18px;

    li {
      cursor: pointer;
    }
  }
}
