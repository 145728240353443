.MenuSection {
  margin-bottom: 250px;
}

.Info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  p {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
  }
}

.AboutUsContent {
  margin: 150px auto 0 auto;

  h1 {
    color: #000;
    font-weight: 300;
  }

  p {
    color: #000;
    font-weight: 400;
  }
}

.AboutUsButtons {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  margin-top: 150px;
}

.AuthButton {
  background-color: #ffb801;
  color: #000;
}

.LoginButton {
  background-color: #272726;
  color: #fff;
}

.AuthButton,
.LoginButton {
  width: 100%;
  text-transform: uppercase;
  border-radius: 15px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.8px;
}

@media (max-width: 576px) {
  .MenuSection {
    margin-bottom: 150px;
  }
  .AboutUsContent {
    max-width: 415px;
  }
  .AboutUsButtons {
    margin-top: 70px;
  }
}

@media (max-width: 992px) {
  .Info {
    display: none;
  }
  .AboutUsContent {
    margin: 40px auto 0 auto;
  }
}

@media (max-width: 291px) {
  .AuthButton,
  .LoginButton {
    padding: 15px 20px;
    font-size: 8px;
  }
  .AboutUsContent {
    h1 {
      font-size: 28px;
    }

    p {
      font-size: 15px;
      margin: 30px 0 0 0;
    }
  }
}

@media (min-width: 291px) {
  .AuthButton,
  .LoginButton {
    padding: 15px 20px;
    font-size: 8px;
  }
  .AboutUsContent {
    h1 {
      font-size: 32px;
    }

    p {
      font-size: 15px;
      margin: 30px 0 0 0;
    }
  }
}

@media (min-width: 330px) {
  .AuthButton,
  .LoginButton {
    font-size: 12px;
  }
  .AboutUsContent {
    h1 {
      font-size: 36px;
    }

    p {
      font-size: 15px;
      margin: 30px 0 0 0;
    }
  }
}

@media (min-width: 389px) {
  .AuthButton,
  .LoginButton {
    font-size: 14px;
  }
  .AboutUsContent {
    h1 {
      font-size: 42px;
    }
  }
}

@media (min-width: 577px) {
  .AboutUsContent {
    h1 {
      font-size: 58px;
    }

    p {
      font-size: 20px;
      margin: 60px 0 0 0;
    }
  }
}

@media (min-width: 768px) {
  .AboutUsContent {
    padding: 0 75px;

    h1 {
      font-size: 62px;
    }
  }
}

@media (min-width: 992px) {
  .AboutUsContent {
    padding: 0 195px;
  }
}

@media (min-width: 1200px) {
  .AboutUsContent {
    padding: 0 285px;
  }
}

@media (min-width: 1400px) {
  .AboutUsContent {
    padding: 0 375px;
  }
}
