.salesGrowth {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 40px;
   padding: 0;
   h1 {
      font-size: 48px;
      color: #000;
      max-width: 900px;
      text-align: center;
      line-height: normal;
   }
   p {
      font-size: 20px;
      color: #000;
      max-width: 810px;
      line-height: normal;
      margin-bottom: 60px;
   }
}

@media (max-width: 389px) {
   .salesGrowth {
      h1 {
         font-size: 28px;
         text-align: center;
      }
      p {
         font-size: 15px;
         margin-top: 10px;
      }
   }
}

@media (max-width: 550px) {
   .salesGrowth {
    
   
      h1 {
         text-align: center;
         font-size: 42px;
      }
      p {
         text-align: center;
         margin-top: 30px;
      }
   }
}

@media (max-width: 1000px) {
   .salesGrowth {
      h1 {
         text-align: center;
      }
   }
}
