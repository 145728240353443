.modal {
   position: absolute;
   position: fixed;
   display: flex;
   right: 0;
   top: 0;
   justify-content: center;
   align-items: center;
   background-color: rgba(0, 0, 0, 0.4);
   width: 96%;
   height: 100%;
   transform: scale(1);
   transition: 0.4s all;
   z-index: 999;

   .modalContent {
      position: absolute;
      .modalContentForm {
         width: 500px;
         height: 500px;
         background-color: #fff;
         display: flex;
         gap: 20px;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         border-radius: 7px;
         .closeModal {
            position: absolute;
            top: 4%;
            right: 4%;
            width: 50px;
            color: #f44336;
            transform: scale(1);
            cursor: pointer !important;
            &:hover {
               transform: scale(1.5);
               transition: all 0.3s;
            }
         }
         .modalContentFormTitle {
            color: #f79633;
            font-size: 30px;
            margin-bottom: 25px;
         }
         .modalContentFormInput {
            width: 400px;
            height: 60px;
            border-radius: 7px;
            outline: 0;
            border: 1px solid var(--fill-low-emphasis);
            font-size: 1.3rem;
            color: var(--fill-low-emphasis);
            background: transparent;
            transition: border-color 0.2s;
            background-color: var(--fill-base);
            border-radius: var(--border-radius-2);
         }
         ::placeholder {
            padding-left: 5px;
            padding-bottom: 5px;
         }
         .modalContentFormTextarea {
            outline: 0;
            border-radius: 7px;
            border: 1px solid var(--fill-low-emphasis);
            font-size: 1.3rem;
            color: var(--fill-low-emphasis);
            background: transparent;
            transition: border-color 0.2s;
            background-color: var(--fill-base);
            border-radius: var(--border-radius-2);
            resize: none;
         }
         ::placeholder {
            padding-left: 5px;
            padding-top: 7px;
         }
         .errors {
            margin-bottom: 10px;
            .errorsText {
             
               color: #ff3333;
            }
         }
         button {
            width: 120px;
            height: 50px;
            box-shadow: 5px 5px 10px #888888;
            &:hover {
               cursor: pointer;
               background-color: #9da3ac;
            }

            .disabledBtn{
               
            }
         }
      }
   }
}
.close {
   transform: scale(0);
}
.errors{
   color: #ff3333;
}