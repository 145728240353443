/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

* {
  font-family: "Nunito", sans-serif;
}

::-webkit-scrollbar-thumb {
  background: #f49100 !important;
  border-radius: 10px !important;
  width: 2px !important;
}

.root {
  width: fit-content !important;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.tooltip-inner {
  font-size: 14px;
}

.popover {
  position: absolute;

  span {
    color: var(--fill-primary);
    font-size: 16px;
  }
}

.react-select__menu {
  border: 1px solid var(--fill-border) !important;
}

.AvatarSpeedDial {
  #Avatar-actions {
    position: absolute !important;
    padding-top: 90px !important;
  }

  .MuiSpeedDial-fab {
    background-color: var(--fill-primary);
  }

  .MuiSpeedDial-fab:hover {
    background-color: var(--fill-primary);
  }
}

th {
  background: var(--fill-primary) !important;
  color: white;
  white-space: nowrap !important;
}

thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

.react-select__menu {
  z-index: 3 !important;
}

.react-pdf__Page__annotations {
  display: none;
}
