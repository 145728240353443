.notFoundDiv {
  margin: auto;
  width: fit-content;
  padding-top: 40px;

  p {
    font-size: 16px;
    margin-top: 10px;
  }
  a {
    color: #f49100;
    font-size: 18px;
    text-decoration: underline;
  }
  a:hover {
    color: #c70000;
  }

  .normalCheckbox {
    span {
      color: black;
    }
  }
}
.checkAndLinks {
  width: fit-content;
  margin: auto;
}
.agreementModal {
  width: 80%;
  max-height: 90vh;
  height: 90vh;
  min-height: 50vh;
  padding: 20px;
}
.agreementModalContent {
  height: 100%;
  padding: 20px;
  embed {
    width: 100%;
    height: 90% !important;
  }
}
