.WeContainer,
.YouContainer {
  display: flex;
  margin-bottom: 50px;

  h1 {
    color: #000000;
    font-size: 48px;
    margin-bottom: 30px;
    font-weight: 300;
  }

  img {
    max-width: 100%;
  }
}

.ImageContainer {
  display: flex;
  align-items: self-end;
  justify-content: end;
}

.SellerContent {
  display: flex;
  flex-direction: column;
  gap: 25px;
  font-style: normal;

  p {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.4px;
    display: flex;
    gap: 20px;
  }

  span {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 50%;
    background: #ffb801;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
  }
}

.NumberedDescriber {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.4px;
}

@media (max-width: 389px) {
  .AuthButton,
  .LoginButton {
    font-size: 14px;
  }
  .AboutUsContent {
    h1 {
      font-size: 42px;
    }
  }
}

@media (max-width: 576px) {
  .WeContainer,
  .YouContainer {
    padding: 0 20px;

    h1 {
      font-size: 32px;
    }
  }
  .NumberedDescriber {
    font-size: 20px;
  }
  .SellerContent {
    width: auto;

    p {
      font-size: 15px;
    }
  }
}

@media (max-width: 992px) {
  .ImageContainer {
    justify-content: center;
  }
  .SellerContent {
    max-width: 550px;
    margin: auto;
  }
  .YouContainer {
    h1 {
      margin-top: 20px;
    }
  }
}

@media (min-width: 992px) {
  .YouContainer {
    h1 {
      margin-top: -35px;
    }
  }
  .SellerContent {
    padding-left: 80px;
  }
}
