.root {
  background: white;
  margin-bottom: 20px;
}

.logo {
  height: 80px;

  img,
  a {
    height: 100%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.seedDials {
  display: flex;
}

.avatar {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.menu {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
