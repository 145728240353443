.root {
  padding: 0 40px 40px;
  min-height: 100vh;
  td,
  th {
    vertical-align: middle;
  }
}
.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 15px;
}
.wrapper {
  min-width: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  background-color: var(--fill-base);
  border-radius: var(--border-radius-2);
  padding: 30px;
}
.thead {
  position: sticky;
}
.theadTh {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    margin-left: 10px;
  }
  svg {
    color: rgba(0, 0, 0, 0.5);
  }
  svg:hover {
    color: rgb(0, 0, 0);
  }
}

.cartPayModalRoot {
  height: 100%;
  padding: 20px;
  overflow: auto;
}
.cartPayModalRoot {
  th,
  td {
    padding: 10px 8px;
  }
  p {
    font-size: 24px;
    text-align: center;
  }
}
.payButton,
.payButtonLoad {
  width: auto;
  margin: auto;
}

.payButtonDiv {
  padding: 0 20px;
  display: flex;
  justify-content: end;
}

.payButton,
.payButtonLoad {
  padding: 10px 20px;
  margin-top: 20px;
}
.payButtonLoad {
  cursor: progress !important;
}

.payTbButton {
  padding: 8px !important;
  width: 100%;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}
.payCheckbox {
  text-align: center;
  input {
    transform: scale(1.5);
  }
}
.collectBtn,
.collectBtnLoad {
  padding: 10px 20px;
  margin-top: 5px;
}
.collectBtnLoad {
  cursor: progress !important;
}

.versionInput {
  display: flex;
  div {
    margin: 0;
  }
  label {
    margin-left: 10px;
  }
  align-items: center;
}

.packageLabelA {
  cursor: pointer;
  color: #1a0dab;
}

.dates {
  div {
    margin-right: 10px;
    float: left;
  }

  font-size: 18px;
  margin-bottom: 10px;

  input {
    margin-left: 5px;
    border: none;
  }
}
@media (max-width: 800px) {
  .root {
    padding: 0 10px 10px;
  }
  .small {
    display: none;
  }
}
