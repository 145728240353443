.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.15rem;
  font-weight: 500;
  line-height: 1.4em;
  white-space: nowrap;
  transition: background-color var(--hover-transition);
  border-radius: var(--border-radius-1);
  border: 0.1rem solid transparent;
  // outline: none;

  &:disabled {
    pointer-events: none;
  }
}

.content {
  display: flex;
  align-items: center;
}

.accessory-variant {
  border-radius: var(--border-radius-1) 0 0 var(--border-radius-1);

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: -1.8rem;
    height: calc(100% + 0.15rem);
    width: 2.2rem;
    background-color: inherit;
    border-radius: var(--border-radius-1);
    z-index: 1;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: -1.2rem;
    height: 1rem;
    width: 1rem;
    background-image: url("./images/arrow-down.svg");
    background-size: cover;
    z-index: 2;
    transform: translateY(-50%);
  }
}

.outlined-variant {
  border: 0.1rem solid var(--fill-low-emphasis);
}

.dropdown-variant {
  transition: none;
  &::after {
    content: "";
    display: block;
    position: absolute;
    right: -2rem;
    height: 107%;
    border: 0.1rem solid transparent;
    width: 1.6rem;
    background-color: inherit;
    border-radius: var(--border-radius-1);
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 1rem;
    width: 1rem;
    top: 50%;
    right: -1.6rem;
    height: 0.8rem;
    width: 0.8rem;
    background-image: url("./images/icon-dropdown.svg");
    background-size: 0.8rem 0.8rem;
    z-index: 1;

    transform: translateY(-50%);
    svg {
      height: 1rem;
      width: 1rem;
    }
  }
}

//size

.small {
  padding: 0 0.4rem;
  font-size: 1rem;
  height: 2.4rem;
}

.normal {
  padding: 16px 10px;
  font-size: 16px;
}

.big {
  min-width: 10rem;
  padding: 1.2rem 0.8rem;
  font-size: 1.2rem;
}

.huge {
  font-size: 1.4rem;
}

//colors

.primary {
  background-color: var(--fill-primary);
  color: var(--fill-base);

  &:hover {
    background-color: var(--fill-primary-core);
  }

  &:focus {
    background-color: var(--fill-primary-core);
  }

  &:active {
    background-color: rgba(var(--fill-primary-code), 0.8);
  }
}

.secondary {
  background-color: var(--fill-high-emphasis);
  color: var(--fill-secondary);

  &:active {
    background-color: rgba(var(--fill-base-code), 0.8);
  }
}

.error {
  background-color: var(--fill-error-core);

  &:hover {
    background-color: var(--fill-error);
  }

  &:active {
    background-color: rgba(var(--fill-error-code), 0.8);
  }
}

.transparent {
  background-color: rgba(255, 255, 255, 0.5);
  border: 0;
  color: var(--fill-low-emphasis);
  &:hover {
    background-color: var(--fill-base);
  }

  &:active {
    background-color: var(--fill-base);
  }
}

.none {
  color: var(--fill-primary-light-core);
}

.fullWidth {
  width: 100%;
}

.link-variant {
  color: var(--fill-links);
  transition: color var(--hover-transition);

  &:hover {
    background-color: transparent;
    color: rgba(var(--fill-links-code), 0.6);
  }

  &:focus {
    color: rgba(var(--fill-links-code), 0.6);
  }
}

.hide {
  opacity: 0;
}

.loading {
  cursor: none;
  pointer-events: none;
}

.spinner {
  position: absolute;
  display: grid;
  align-items: center;
  width: 1.2rem;
  height: 1.2rem;
}

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  line-height: initial;
}

.fillMediumEmphasis {
  color: var(--fill-medium-emphasis);
}

.radius1 {
  border-radius: var(--border-radius-1);
}

.radius2 {
  border-radius: var(--border-radius-2);
}

.radius3 {
  border-radius: var(--border-radius-3);
}

.round-variant {
  padding: 0.6rem 0.8rem;
  border-radius: 10rem;

  &:focus {
    background-color: var(--elevation-1);
  }
}
.disabled {
  background-color: var(--fill-medium-emphasis);
  color: white;
}
