.root {
  position: relative;
  min-height: 100vh;
}
.wallpaper {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.content {
  max-width: 540px;
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.window {
  width: 100%;
  background-color: var(--fill-base);
  padding: 45px 60px;
  border-radius: var(--border-radius-3);
}
.login {
  margin-bottom: var(--spacing-m);
}
.registerButton:hover{
  opacity: 0.8;
}
.login:hover{
  opacity: 0.8;
}
.or {
  color: var(--fill-low-emphasis);
  position: relative;
  text-align: center;
  margin: 40px auto;
  font-size: 14px;
  &:before {
    content: "";
    position: absolute;
    width: 38%;
    height: 2px;
    background-color: var(--fill-border);
    left: 0;
    top: 8px;
  }
  &:after {
    content: "";
    position: absolute;
    width: 38%;
    height: 2px;
    background-color: var(--fill-border);
    right: 0;
    top: 8px;
  }
}

.socials {
  display: flex;
  justify-content: center;
}
.social {
  border: 1px solid var(--fill-border);
  border-radius: var(--border-radius-2);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(2) {
    margin: 0 20px;
  }
}
.forgot {
  text-align: center;
  color: var(--fill-low-emphasis);
  display: block;
  margin: 30px 0 0;
}

.options {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.divider {
  height: 15px;
  width: 1px;
  background-color: var(--fill-low-emphasis);
  margin: 0 20px;
}
.welcomeTitle{
  text-align: center;
  img{
    width: 300px;
    margin: -70px 0;
  }
}
.title {
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  color: var(--fill-low-emphasis);
}