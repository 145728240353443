.zoom {
  position: relative;
  text-align: center;
  img {
    height: auto;
    max-height: 100% !important;
    max-width: 100% !important;
    width: auto !important;
  }
  &__loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.3);
    display: none;
  }
}
.downloadIcon {
  position: absolute;
  right: 5px;
  cursor: pointer;
  top: 5px;
  background: white;
  z-index: 999;
  border-radius: 8px;
}
.addIcon {
  position: absolute;
  right: 40px;
  cursor: pointer;
  top: 5px;
  background: white;
  z-index: 999;
  border-radius: 8px;
}
.downloadIcon:hover {
  color: #f33;
}
