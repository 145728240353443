.infoSectionContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h1 {
    color: #000000;
    font-size: 48px;
    margin-bottom: 30px;
    font-weight: 300;
  }
}
.infoSectionContent {
  display: flex;
  flex-direction: column;
  gap: 25px;
  font-style: normal;

  p {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.4px;
    display: flex;
    gap: 20px;
  }

  span {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 50%;
    background: #ffb801;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 689px) {
  .infoSectionContainer {
    h1 {
      font-size: 42px;
    }
    p {
      font-size: 14px;
    }
  }
}

@media (max-width: 389px) {
  .infoSectionContainer {
    h1 {
      font-size: 32px;
    }
    p {
      font-size: 15px;
    }
  }
}
