.root {
  padding: 0 40px 40px;
  min-height: 100vh;

  td,
  th {
    vertical-align: middle;
  }
}

.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 15px;
}

.wrapper {
  min-width: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  background-color: var(--fill-base);
  border-radius: var(--border-radius-2);
  padding: 30px;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

.normal {
  width: 150px !important;
  margin-right: 8px;
  border: unset !important;
  margin-bottom: 8px !important;

  :global {
    .react-select__control {
      min-height: 47px;
    }
  }
}

.label {
  margin-right: 10px;
}

.thead {
  position: sticky;
}

.theadTh {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    margin-left: 10px;
  }
}

.small {
  width: 80px !important;
  position: absolute;
  top: 55px;

  :global {
    .react-select__control {
      min-width: 75px;
    }
  }
}

.normal {
  width: 150px !important;
}

.MPLogos {
  text-align: center;
  width: 5rem;

  img {
    min-width: 44px;
    width: 80%;
  }
}

.dates {
  display: flex;
  align-items: center;
  margin: 0 0 5px 10px;

  div {
    margin-right: 10px;
    float: left;
  }

  font-size: 18px;
  margin-bottom: 10px;

  input {
    margin-left: 5px;
    border: none;
  }
}
.analyticsButton,
.analyticsButtonLoad {
  height: 38px;
  margin-left: 10px;
}
.analyticsLoad {
  cursor: progress !important;
}

@media (max-width: 800px) {
  .root {
    padding: 0 10px 10px;
  }
  .small {
    display: none;
  }
}
