.root {
  padding: 0 40px 40px;
  min-height: 100vh;
}
.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 15px;
}
.wrapper {
  min-width: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  background-color: var(--fill-base);
  border-radius: var(--border-radius-2);
  padding: 30px;
}

.addSubscribtion {
  padding: 0;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    button {
      margin-left: 10px;
    }
    input {
      width: 250px;
    }
  }

  margin-bottom: 20px;
}
.updateSubscribtion {
  display: flex;
  padding: 0;
  button {
    margin-left: 10px;
  }
  input {
    width: 250px;
  }

  margin-bottom: 20px;
}
.addSubscribtionLoading,
.sendLoading {
  cursor: progress !important;
}
.addSubscribtionName {
  margin-bottom: 0;
}
.addSubscribtionImportance {
  min-width: 140px;
  margin-left: 10px;
  margin-bottom: 0;
}

.emailAdd {
  text-align: center;
}
.addEmail {
  text-align: center;
}
.addEmailIcon {
  cursor: pointer;
}

.EmailAddPopup {
  padding: 20px;
  textarea {
    border: 1px solid #dee2e6;
    border-radius: 20px;
    padding: 1.1rem 1.2rem;
    margin: 20px 0;
    max-height: 60vh;
    overflow: auto;
  }
}
.normalCheckbox {
  span {
    color: black;
  }
  margin-bottom: 20px;
}
.editIcon,
.deleteIcon {
  cursor: pointer;
}
.deleteIconLoad {
  cursor: progress;
}
.addButton {
  cursor: pointer;
}
.addButtonLoad {
  cursor: progress !important;
}
.payCheckbox {
  vertical-align: middle;
  width: 20px;
  text-align: center;
  cursor: pointer;
  input {
    transform: scale(1.5);
  }
}
.deleteSubscribtion,
.deleteSubscribtion:hover {
  background: #f33;
}
@media (max-width: 800px) {
  .root {
    padding: 0 10px 10px;
  }
}
