.root {
  padding: 0 40px 40px;
  min-height: 100vh;
}

.content {
  border-radius: var(--border-radius-3);
}

.banner {
  background-color: var(--fill-base);
  border-radius: var(--border-radius-3);
}

.title,
.newLink {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 20px;
}

.newLink {
  text-decoration: underline;
  cursor: pointer;
}

.revenueBlocks {
  padding: 0 20px;
}

.revenueChart {
  padding: 0 20px;
}

.revenueBlockCol {
  padding: 10px 10px 30px 10px;
}

.revenueBlock {
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid;
}

.revenueBlockName {
  font-size: 24px;
  font-weight: bold;
}

.revenueBlockValue {
  color: black;
  margin-top: 8px;
  font-size: 18px;
}

.revenueTitle {
  text-align: center;
  font-size: 24px;
  margin: 40px 0 10px 0;
}

.priceChangeTitle {
  span {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word; /* Important for long words! */
  }
  color: #f49100;
  font-size: 16px;
  margin: 0 0 5px 0;
}

.priceChangeItems {
  span {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  font-size: 16px;
  cursor: pointer;
}

.priceChangeListGroup {
  div {
    padding: 12px;
    border-bottom: 1px solid rgb(157, 163, 172);
  }

  p {
    margin: 0;
  }
}

.messagesTitle {
  position: relative;
  background-color: var(--fill-base);
  border-radius: var(--border-radius-3);
  height: 100%;
  p {
    margin: auto;
    position: relative;
    width: fit-content;
    font-size: 18px;
    text-align: center;
    padding: 8px 0;
  }
}

.favoriteCountBadge {
  border-radius: 100px;
  position: absolute;
  top: -3px;
  right: -20px;
  font-size: 11px;
}
.salesToggleDiv {
  display: flex;
  align-items: center;
  margin: 30px 0 10px 0;
  column-gap: 20px;
  p {
    margin: 0;
  }
}
.PriceChangePopup {
  max-height: 80vh;
  padding: 20px;
}
.PriceChangeTable {
  overflow: auto;
  height: 100%;
  display: flex;
}
.cardTitle {
  cursor: pointer;
  color: #1a0dab;
}

.cardTitle:hover {
  text-decoration: underline;
}
.MPLogos {
  text-align: center;
  width: 5rem;

  img {
    width: 80%;
  }
}
.OrderTable {
  td,
  th {
    vertical-align: middle;
  }
  padding: 20px;
  overflow: auto;
}
.greenColor {
  td {
    background-color: #8ec64490;
  }
}

.redColor {
  td {
    background-color: rgba(249, 97, 134, 0.5);
  }
}

.orangeColor {
  td {
    background-color: rgba(244, 145, 0, 0.5);
  }
}

.packageLabel {
  text-align: center;
}

.packageLabelA {
  text-decoration: underline;
  color: #1a0dab;
  cursor: pointer;
}
@media (max-width: 800px) {
  .root {
    padding: 0 10px 10px;
  }
}
