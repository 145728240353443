.root {
  padding: 0 40px 40px;
}

.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
}

.title2 {
  font-size: 28px;
  color: var(--fill-low-emphasis);
  margin-bottom: 30px;
  margin-top: 30px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--fill-base);
  border-radius: var(--border-radius-2);
  padding: 30px;
  margin-top: 15px;
  .containerTable {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .table {
      width: 350px;
      font-size: 16px;
    }
  }
}

.theadTh {
  display: flex;
  justify-content: space-between;

  div {
    margin-left: 10px;
  }

  svg {
    color: rgba(0, 0, 0, 0.5);
  }

  svg:hover {
    color: rgb(0, 0, 0);
  }
}

.UploadUserDocument {
  .uploadDocument {
    width: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    height: 100%;
    min-height: 250px;
  }

  .uploadIcon {
    width: 4rem;
    height: 4rem;
  }

  .uploadIcon:hover {
    width: 5rem;
    height: 5rem;
  }

  .okAndCancelIcons {
    display: flex;
    width: 120px;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px 0;
    border-radius: 8px;
    align-items: center;
  }
}

.bankInfoTable {
  td {
    vertical-align: middle;
  }

  div {
    margin: 0;
    height: 38px;
    min-height: 38px !important;
  }

  button {
    height: 30px;
    margin: 0;
  }
}
.toggleStyle {
  div {
    height: auto !important;
  }
}
.MPLogisticTitle {
  display: flex;
  justify-content: space-between;
  height: auto !important;
  align-items: center;
  div {
    height: auto;
  }
  font-size: 28px;
}

.changeButton,
.changeButtonLoad {
  display: flex;
  justify-content: end;
}

.changeButtonLoad {
  button {
    cursor: progress !important;
  }
}

.productDocument {
  img {
    width: 100%;
  }
}
.documentUpload {
  margin-top: 50px;

  .addButton {
    padding: 8px 16px;
  }
  .packageLabel {
    text-align: center;
  }

  .packageLabelA {
    cursor: pointer;
    color: #1a0dab;
  }
  .deleteIcon {
    cursor: pointer;
  }
}

.treeWrapperHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    height: 30px;
  }
}
.CardHeader,
.CardHeaderPrice {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  * {
    margin: 0;
  }
}
.CardHeaderPrice {
  grid-template-columns: 20px;
}
.fileDownloadA {
  color: #f49100;
  text-decoration: underline;
}
.fileDownloadA:hover {
  text-decoration: none;
}
.versionInput {
  display: flex;
  div {
    margin: 0;
  }
  label {
    margin-left: 10px;
  }
  align-items: center;
}
@media (max-width: 800px) {
  .root {
    padding: 0 10px 10px;
  }
}
