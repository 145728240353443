.root {
  display: flex;
  align-items: center;
}
.container {
  display: block;
  position: relative;
  height: 1.4rem;
  width: 1.4rem;
}

.container .input {
  position: absolute;
  height: inherit;
  width: inherit;
  opacity: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
  user-select: none;
  background-image: url(./images/unchecked.svg);
  background-size: 1.4rem 1.4rem;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 1.4rem;
    width: 1.4rem;
    background-image: url(./images/checked.svg);
    background-size: 1.4rem 1.4rem;
    opacity: 0;
    transform: scale(0.8);
    transition: all 0.15s;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 1.4rem;
    width: 1.4rem;
    background-image: url(./images/untick.svg);
    background-size: 1.4rem 1.4rem;
    opacity: 0;
    transform: scale(0.8);
    transition: all 0.15s;
  }
}

.container .input:checked ~ .checkmark:after {
  opacity: 1;
  transform: scale(1);
}

.minus:before {
  opacity: 1;
  transform: scale(1);
}

.label {
  margin-left: 0.7rem;
  color: var(--fill-low-emphasis);
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.15s;
  flex: 1 1 100%;
}

.active {
  color: var(--fill-high-emphasis);
}

.onFocus {
  color: var(--fill-high-emphasis);
}
.minus {
  .checkmark {
    background-image: url(./images/icon-checkbox-untick.svg);
    &:after {
      background-image: url(./images/checked.svg);
    }
  }
  .input:checked ~ .checkmark {
    background-image: none;
  }
}

.normal {
  .container {
    height: 2.4rem;
    width: 2.4rem;
  }

  .checkmark {
    height: 2.4rem;
    width: 2.4rem;
    background-size: 2.4rem 2.4rem;

    &:after {
      height: 2.4rem;
      width: 2.4rem;
      background-size: 2.4rem 2.4rem;
    }
  }
  

  .label {
    font-size: 1.4rem;
    margin-left: 1.6rem;
  }  

  .active {
    color: unset;
  }
 
}