.ContactSection {
  margin-top: 400px;
  padding: 0;

  @media (max-width: 576px) {
    margin-top: 200px;
  }
}

.ContactContent {
  h1 {
    font-style: normal;
    text-align: center;
    margin-bottom: 80px;
    font-weight: 300;
    font-size: 48px;
    color: #000;

    @media (max-width: 576px) {
      font-size: 32px;
    }
  }
}

.ContactInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
  width: fit-content;
}

.ContactPhone {
  display: flex;
}

.ContactMail {
  display: flex;
  margin-bottom: 150px;
}

.ContactPhoneNumber,
.ContactMailText {
  a {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #000000;
    margin-left: 50px;
    text-transform: uppercase;

    @media (max-width: 340px) {
      font-size: 18px !important;
      margin-left: 30px;
    }

    @media (max-width: 576px) {
      font-size: 25px;
      margin-left: 30px;
    }
  }
}

.ContactPhoneIcon,
.ContactMailIcon {
  img {
    margin-top: 10px;
    width: 30px;
  }
}
