.accordionButton {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  cursor: grab;
  padding-bottom: 20px;
  .accordion-button {
    color: red;
  }
  button {
    border-radius: 15px;
  }
  button:not(.collapsed)::after {
    margin-top: 20px;
    margin-left: 15px;
  }

  button::after {
    padding-left: 5px;
  }
}
.faqBody {
  border-radius: 15px;
  background-color: var(--fill-primary);
}

.row {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: centre;
  align-items: center;
  .col {
    display: flex;
    justify-content: flex-end;
    gap: 40px;
    padding-right: 30px;

    svg {
      position: absolute;
      top: 24%;
      z-index: 9999;
      margin-top: 20px;
    }
  }
  .col2 {
    justify-content: flex-start;
    padding-left: 30px;
  }
}
