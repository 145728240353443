.alghorithmOfWorkSection {
   margin-top: 144px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 94px;
   h1 {
      font-size: 48px;
      color: #000;
   }
   .alghorithmOfWorkSectionDiagram {
      display: flex;
      justify-content: center;
      align-items: center;
      .alghorithmOfWorkSectionList {
         width: 355px;
         height: 607px;
         padding-left: 60px;
         display: flex;
         flex-direction: column;
         gap: 20px;

         p {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.4px;
            display: flex;
            justify-content: center;
            gap: 12px;
         }

         span {
            width: 24px;
            height: 24px;
            min-width: 24px;
            border-radius: 50%;
            background: #ffb801;
            display: flex;
            align-items: center;
            justify-content: center;
         }
      }
   }
}

@media (max-width: 1000px) {
   .alghorithmOfWorkSection {
      gap: 70px;
      h1 {
         font-size: 48px;
         text-align: center;
      }
      .alghorithmOfWorkSectionDiagram {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         gap: 60px;
         width: 100%;
         .alghorithmOfWorkSectionList {
            p {
               font-size: 15px;
            }
            h1 {
               margin-bottom: 30px;
            }
         }
      }
   }
}

@media (max-width: 775px) {
   .alghorithmOfWorkSection {
      gap: 0px;
      h1 {
         font-size: 45px;
         text-align: center;
         margin-bottom: 40px;
      }
      .alghorithmOfWorkSectionDiagram {
         gap: 0px;
      }
   }
}

@media (max-width: 397px) {
   .alghorithmOfWorkSection {
      gap: 0px;
      h1 {
         font-size: 24px;
         text-align: center;
      }
      .alghorithmOfWorkSectionDiagram {
         gap: 0px;
      }
   }
}
