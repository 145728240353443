.root {
  padding: 0 40px 40px;
  min-height: 100vh;

  td,
  th {
    vertical-align: middle;
  }
}

.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 15px;
}

.wrapper {
  min-width: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  background-color: var(--fill-base);
  border-radius: var(--border-radius-2);
  padding: 30px;
}

.filters {
  display: flex;
  flex-wrap: wrap;
}

.normal {
  width: 150px !important;
  margin-right: 8px;
  border: unset !important;
  margin-bottom: 8px !important;

  :global {
    .react-select__control {
      min-height: 47px;
    }
  }
}

.searchInput {
  min-height: 49px !important;
  height: 49px;
  margin-right: 8px;
  margin-bottom: 8px !important;
}

.search {
  display: flex;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
}

.reset {
  color: var(--fill-primary);
  cursor: pointer;
}

.tag {
  border: 1px solid var(--fill-border);
  border-radius: var(--border-radius-3);
  padding: 5px 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background-color: var(--fill-base);
}

.label {
  margin-right: 10px;
}

.thead {
  position: sticky;
}

.theadTh {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    margin-left: 10px;
  }

  svg {
    color: rgba(0, 0, 0, 0.5);
  }

  svg:hover {
    color: rgb(0, 0, 0);
  }
}

.small {
  width: 80px !important;
  position: absolute;
  top: 55px;

  :global {
    .react-select__control {
      min-width: 75px;
    }
  }
}

.pagination {
  position: relative;
}

.resetFilter {
  padding: 13px 16px 6px;
  color: var(--fill-primary);
  display: block;
  cursor: pointer;
}

.activeArrow {
  svg {
    color: rgb(0, 0, 0);
  }
}

.cartModal {
  max-width: 80% !important;
}

.cartModalRoot,
.cartPayModalRoot {
  height: 100%;
  padding: 20px;
  overflow: auto;
}

.cartPayModalRoot {
  th,
  td {
    padding: 10px 8px;
  }

  p {
    font-size: 24px;
    text-align: center;
  }
}

.approveButton,
.approveButtonLoading {
  margin-left: 5px;
  margin-bottom: 12px;
  margin-top: 7px;

  button {
    height: 40px;
    font-size: 14px;
  }
}

.approveButtonLoading {
  button {
    cursor: progress !important;
  }
}

.marketPlacesLogo {
  padding: 5px;
  position: relative;

  img {
    height: 40px;
    margin: 0 5px;
  }

  height: 40px;
}

.cartModalTable {
  margin: 0;
}

.orderStatusSelect {
  margin: 0;
}

.payButton,
.payButtonLoad,
.commentButton,
.commentButtonLoad {
  width: auto;
  margin: auto;
  padding: 10px 20px;
  margin-top: 20px;
}

.payButtonDiv,
.commentButtonDiv {
  padding: 0 20px;
  display: flex;
  justify-content: end;
}

.payButtonLoad,
.commentButtonLoad {
  cursor: progress !important;
}

.cartLinkIcon {
  color: var(--fill-primary) !important;
}

.cardTitle {
  cursor: pointer;
  color: #1a0dab;
}

.cardTitle:hover {
  text-decoration: underline;
}

.packageLabel {
  text-align: center;
}

.packageLabelA {
  text-decoration: underline;
  color: #1a0dab;
  cursor: pointer;
}

.normal {
  width: 150px !important;
}

.payTbButton {
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.chooseAllTbButton,
.chooseAllTbButtonProgress {
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  height: 10px;
  margin: 5px auto 0 auto;
  font-size: 12px;
}

.chooseAllTbButtonProgress {
  cursor: progress !important;
}

.payCheckbox {
  text-align: center;

  input {
    transform: scale(1.5);
  }
}

.collectBtn,
.collectBtnLoad {
  padding: 10px 20px;
  margin-top: 5px;
}

.collectBtnLoad {
  cursor: progress !important;
}

.downloadList {
  button {
    background-color: var(--fill-primary);
    color: var(--fill-base);
    border-radius: var(--border-radius-3);
    border: none;
    margin-top: 7px;
  }

  button:hover {
    background-color: var(--fill-primary);
  }

  button:focus-visible {
    background: var(--fill-primary) !important;
    color: red !important;
  }

  button:active {
    background-color: var(--fill-primary) !important;
    opacity: 0.8;
  }
}

.cancelButton {
  cursor: pointer;
}

.cancelButton,
.cancelButtonLoad {
  svg {
    color: white !important;
  }
}

.cancelButtonLoad {
  cursor: progress !important;
}

.MPLogos {
  text-align: center;
  width: 5rem;

  img {
    width: 80%;
  }
}

canvas {
  width: 100% !important;
  height: auto !important;
}

.dates {
  display: flex;
  align-items: center;
  margin: 0 0 5px 10px;

  div {
    margin-right: 10px;
    float: left;
  }

  font-size: 18px;
  margin-bottom: 10px;

  input {
    margin-left: 5px;
    border: none;
  }
}
.normalCheckbox {
  width: fit-content;
  margin: auto;
}

.contextMenu {
  position: absolute;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000 !important;
  background: #fff;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 8px 16px;
    cursor: pointer;
    color: #44596e;
  }
  li:hover {
    background-color: rgba(244, 145, 0, 0.2);
  }
}

.greenColor {
  td {
    background-color: #8ec64490;
  }
}

.redColor {
  td {
    background-color: rgba(249, 97, 134, 0.5);
  }
}

.orangeColor {
  td {
    background-color: rgba(244, 145, 0, 0.5);
  }
}

.commentPopup {
  width: 500px;
  textarea {
    border: 1px solid rgb(223, 229, 233);
    border-radius: 20px;
    padding: 16px;
    width: 100%;
  }
}
.uploadCommentImage {
  margin-bottom: 35px;
  text-align: center;
}

.uploadQR {
  margin-bottom: 10px;
}

@media (max-width: 800px) {
  .root {
    padding: 0 10px 10px;
  }
  .small {
    display: none;
  }
}
