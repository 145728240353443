.root {
  padding: 0 40px 40px;
  min-height: 100vh;

  td,
  th {
    vertical-align: middle;
  }
}

.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 15px;
}

.wrapper {
  min-width: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  background-color: var(--fill-base);
  border-radius: var(--border-radius-2);
  padding: 30px;
}

.theadTh {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    margin-left: 10px;
  }
}

.pagination {
  position: relative;
}

.SwitchL {
  cursor: progress;
}

.stockSwitch {
  padding: 5px 0;
  text-align: center;

  p {
    margin-bottom: 5px;
  }
}

@media (max-width: 800px) {
  .root {
    padding: 0 10px 10px;
  }
}

.modalWindow {
  padding: 20px 40px;
}
.modalTitle {
  padding-top: 10px;
}
.modalWindowChildren {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 14px;
}
