.root {
  padding: 20px;
  font-size: 28px;
}

.link {
  font-size: 40px;
  color: var(--fill-primary);
}

.Home {
  padding: 0;
  margin: 0;
}

.Home * {
  font-family: "Inter", sans-serif;
}
