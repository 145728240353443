.root {
  padding: 0 40px 40px;
  min-height: 100vh;
}

.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 15px;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.filters {
  display: flex;
  flex-wrap: wrap;
}

.priceDiv,
.marketPlaceDiv {
  display: flex;
}

.list {
  grid-template-columns: 1fr;
}

.short {
  width: 120px !important;
}

.normal {
  width: 150px !important;
}

.big {
  width: 220px !important;
}

.small {
  width: 80px !important;
  position: absolute;
  top: 55px;

  :global {
    .react-select__control {
      min-width: 75px;
    }
  }
}

.normalCheckbox,
.short,
.normal,
.big,
.small {
  margin-right: 8px;
  border: unset !important;
  margin-bottom: 8px !important;

  :global {
    .react-select__control {
      min-height: 47px;
    }
  }
}

.normalCheckbox {
  span {
    color: black;
  }
}

.priceInput,
.searchInput {
  min-height: 49px !important;
  height: 49px;
  margin-right: 8px;
  margin-bottom: 8px !important;
}

.priceInput {
  width: 100px;
  padding: 0;

  input {
    padding: 8px 14px !important;
  }
}

.placeholder {
  color: var(--fill-medium-emphasis);
  margin-right: 10px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
}

.reset {
  color: var(--fill-primary);
  cursor: pointer;
}

.resetFilter {
  padding: 13px 16px 6px;
  color: var(--fill-primary);
  display: block;
  cursor: pointer;
}

.tag {
  border: 1px solid var(--fill-border);
  border-radius: var(--border-radius-3);
  padding: 5px 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background-color: var(--fill-base);
}

.label {
  margin-right: 10px;
}

.pagination {
  position: relative;
}

.priceText {
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  color: black;
}

.priceSlider {
  margin-left: 10px;
  color: var(--fill-primary) !important;
}

.priceButton {
  padding-left: 20px;

  button {
    width: auto;
  }
}

.search {
  display: flex;
}

.searchButton {
  height: 49px;
  margin-left: 10px;
  width: 100px;
}

.p1 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #242424;
  margin: 0 0 4px;
}

.p2 {
  font-size: 16px;
  line-height: 22px;
  color: #868695;
  margin: 0;
}

.p2 button {
  border-bottom: 1px dashed;
  border-color: initial;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  color: #242424;
  border-color: rgba(36, 36, 36, 0.4);
  font-size: 16px;
  line-height: 22px;
  transition: color 0.3s ease, border 0.3s ease;
}

.p2 button:hover {
  color: var(--fill-primary);
  border-color: var(--fill-primary);
}

.notFoundResult {
  margin: auto;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.notFoundResultWrap {
  max-width: 270px;
  margin-right: 32px;
}

.notFoundResult::after {
  content: "";
  display: block;
  width: 90px;
  height: 80px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(//static.wbstatic.net/i/v3/catalog/not-found.svg);
}

.collectionModalRoot {
  height: 100%;
  padding: 20px;
  overflow: auto;
}

.downloadProductsButton,
.downloadProductsButtonLoad {
  padding: 10px 20px;
  margin-top: 20px;
  margin-left: 10px;
}
.collectionButton,
.collectionButtonLoad {
  padding: 10px 20px;
  margin-top: 20px;
}
.collectionButtonLoad,
.downloadProductButtonLoad {
  cursor: progress !important;
}
.IsMpBannedContent {
  position: relative;
}
.IsMpBannedDisabled {
  position: absolute;
  width: 40px;
  margin: 0 5px;
  height: 100%;
  background-color: grey;
  opacity: 0.8;
  border-radius: 20px;
}
.marketPlacesLogo {
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    cursor: pointer;
  }

  height: 50px;
}

.warehouseName {
  font-size: 32px;
  margin-bottom: 10px;
  color: #f49100;
}

@media (max-width: 800px) {
  .root {
    padding: 0 10px 10px;
  }
  .priceSlider {
    width: 90% !important;
  }
  .small {
    display: none;
  }
}
