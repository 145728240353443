.ReliablySection {
  margin: 260px auto 370px auto;

  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    color: #1d1d1b;
    margin-bottom: 75px;
  }
}

.ReliablyLine {
  align-items: center;
  display: flex;
}

.ReliablySectionContent {
  display: flex;
  flex-direction: column;
  gap: 50px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: black;
  }

  img {
    margin-right: 55px;
    width: auto;
  }
}

@media (max-width: 340px) {
  .ReliablySection {
    max-width: 100% !important;
    padding: 30px;
    margin: 160px auto 270px auto;

    h1 {
      font-size: 25px !important;
    }
  }
}

@media (max-width: 576px) {
  .ReliablySection {
    max-width: 100% !important;
    padding: 30px;
    margin: 160px auto 270px auto;

    h1 {
      font-size: 32px;
    }
  }
  .ReliablySectionContent {
    p {
      font-size: 15px;
    }

    img {
      margin-right: 20px;
    }
  }
}

@media (max-width: 992px) {
  .ReliablySection {
    max-width: 460px;
  }
}

@media (min-width: 993px) {
  .ReliablySection {
    padding: 0 230px;
  }
}

@media (min-width: 1200px) {
  .ReliablySection {
    padding: 0 320px;
  }
}

@media (min-width: 1400px) {
  .ReliablySection {
    padding: 0 410px;
  }
}
