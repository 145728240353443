.root {
  background-color: var(--fill-base-light);
  display: flex;
  min-height: 100vh;
  min-width: fit-content;
}

.content {
  width: calc(100% - 120px);
  margin-top: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 40px 20px;
}

.avatar {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.name {
  margin-right: 15px;
}

.mobileHeader {
  display: none;
  margin-top: -40px;
}

.drawer {
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 999;
}

@media (max-width: 800px) {
  .content {
    width: 100%;
  }
  .avatar {
    display: none;
  }
  .header {
    padding: 30px 10px 20px;
  }
}
