.root {
  padding: 0 40px 40px;
  min-height:100vh;
}
.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 10px;
}
.subtitle,
.subtitleUpdate {
  font-size: 18px;
  color: var(--fill-low-emphasis);
  margin-bottom: 20px;
}
.subtitleUpdate {
  margin-top: 40px;
}
.wrapper {
  display: flex;
}
.content {
  width: 70%;
  margin-right: 30px;
  border-radius: var(--border-radius-3);
  background-color: var(--fill-base);
  min-height: 75vh;
  padding: 30px 25px;
}
.banner {
  width: 30%;
  border-radius: var(--border-radius-3);
  background-color: var(--fill-base);
  min-height: 75vh;
}
.btn {
  width: 180px;
  padding: 18px 0;
  font-size: 16px;
  height: 55px;
}
.inputs {
  display: flex;
  .btn {
    margin-left: 5px;
  }
}
.input {
  position: relative;
  width: 60px;
  margin-right: 12px;
  background-color: var(--fill-base-light);
  &:active,
  &:focus,
  &:hover,
  &:focus-within {
    background-color: var(--fill-base-light) !important;
  }
}
.dots {
  position: relative;
  height: 50px;
  width: 5px;
  margin-right: 12px;
  &:after {
    content: "";
    position: absolute;
    background-color: var(--fill-border);
    width: 5px;
    height: 5px;
    border-radius: 10px;
    top: 18px;
  }
  &:before {
    content: "";
    position: absolute;
    background-color: var(--fill-border);
    width: 5px;
    height: 5px;
    border-radius: 10px;
    top: 31px;
  }
}
@media (max-width:800px){
  .root {
    padding: 0 10px 10px;
  }
}