.SuppliersSection {
  margin-bottom: 150px;

  .Info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    p {
      color: #000;
      font-size: 20px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1px;
    }
  }
  .SuppliersSectionContent {
    margin: 150px auto 0 auto;
    max-width: 600px;
    h1 {
      color: #000;
      font-weight: 300;
      font-size: 62px;
      margin-bottom: 54px;
    }

    .SuppliersSectionContentText {
      max-width: 580px;
      margin-bottom: 50px;
      font-size: 20px;

      .SuppliersSectionContentP {
        margin-top: 20px;
        margin-bottom: 18px;
      }
    }
    .SupplierSectionButtons {
      display: flex;
      column-gap: 20px;
      margin-top: 150px;
      .AuthButton {
        background-color: #ffb801;
        color: #000;
        width: 270px;
        height: 50px;
        border-radius: 15px;
        text-transform: uppercase;
        font-size: 16px;
      }

      .LoginButton {
        background-color: #272726;
        color: #fff;
        width: 270px;
        height: 50px;
        border-radius: 15px;
        text-transform: uppercase;
        margin-left: 8px;
        font-size: 16px;
      }
    }

    p {
      color: #000;
      font-weight: 400;
    }
  }
}
.applicationModal {
  padding: 20px;
}
.title {
  font-size: 24px;
  margin-bottom: 30px;
  color: var(--fill-low-emphasis);
}
.content {
  max-width: 1000px;
  text-align: center;
  padding: 20px;
  max-height: 80vh;
  overflow: auto;
}

.applicationInput {
  margin-bottom: 20px;
}

@media (max-width: 689px) {
  .SuppliersSection {
    .Info {
      img {
        width: 200px;
      }
      p {
        font-size: 16px;
      }
    }
    .SuppliersSectionContent {
      h1 {
        font-size: 42px;
      }
      .SupplierSectionButtons {
        .AuthButton {
          font-size: 12px;
        }
        .LoginButton {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 559px) {
  .SuppliersSection {
    padding-left: 70px;
    padding-right: 70px;
    .Info {
      img {
        width: 180px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 520px) {
  .SuppliersSection {
    padding-left: 20px;
    padding-right: 20px;

    .Info {
      img {
        width: 150px;
      }
      p {
        font-size: 14px;
        color: #ffb801;
      }
    }

    .SuppliersSectionContent {
      .SupplierSectionButtons {
        .AuthButton {
          font-size: 14px;
        }
        .LoginButton {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 389px) {
  .SuppliersSection {
    padding-left: 20px;
    padding-right: 20px;
    .Info {
      img {
        width: 150px;
      }
      p {
        font-size: 12px;
      }
    }
    .SuppliersSectionContent {
      h1 {
        font-size: 28px;
      }

      .SupplierSectionButtons {
        .AuthButton {
          font-size: 9px;
        }
        .LoginButton {
          font-size: 9px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .SuppliersSection {
    .Info {
      display: none;
    }
  }
}
