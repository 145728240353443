.root {
  padding: 0 40px 40px;
  min-height:100vh;
}
.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 15px;
}
.wrapper {
  display: flex;
  flex-direction: column;
}
.filters {
  display: flex;
  flex-wrap: wrap;
}
.list {
  grid-template-columns: 1fr;
}
.short {
  width: 120px !important;
}
.normal {
  width: 150px !important;
}
.big {
  width: 220px !important;
}
.small {
  width: 80px !important;
  position: absolute;
  top: 55px;
  :global {
    .react-select__control {
      min-width: 75px;
    }
  }
}
.normalCheckbox,
.short,
.normal,
.big,
.small {
  margin-right: 15px;
  border: unset !important;
  margin-bottom: 15px !important;
  :global {
    .react-select__control {
      min-height: 47px;
    }
  }
}
.normalCheckbox {
  span {
    color: black;
  }
}
.select {
  background-color: var(--fill-base);
  padding: 15px;
  border-radius: var(--border-radius-3);
  border: 1px solid var(--fill-border);
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.placeholder {
  color: var(--fill-medium-emphasis);
  margin-right: 10px;
}
.view {
  width: 44px;
  height: 44px;
  background-color: var(--fill-base);
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-2);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
}
.reset {
  color: var(--fill-primary);
  cursor: pointer;
}
.resetFilter {
  padding: 13px 16px 6px;
  color: var(--fill-primary);
  display: block;
  cursor: pointer;
}
.tag {
  border: 1px solid var(--fill-border);
  border-radius: var(--border-radius-3);
  padding: 5px 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background-color: var(--fill-base);
}
.label {
  margin-right: 10px;
}
.pagination {
  position: relative;
}
.priceText {
  font-size: 16px;
  margin-bottom: 5px;
}
.priceSlider {
  margin-left: 10px;
  color: var(--fill-primary) !important;
}
.priceButton {
  padding-left: 20px;
  button {
    width: auto;
  }
}
.noFavoritesTitle{
  font-size: var(--fs-homePage-lg);
  text-align: center;
}
@media (max-width:800px){
  .root {
    padding: 0 10px 10px;
  }
  .small{
    display: none;
  }
}