.wrapper {
  width: 100px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  position: sticky;
  top: 0;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.icon {
  margin-top: 15px;
  position: relative;
}

.logout {
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: -5px;
}

.logo {
  margin-top: 10px;

  img {
    width: 100%;
  }
}

.favoriteCountBadge {
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 100px;
}

.logoutModal {
  text-align: center;
  padding: 30px;
}

.text {
  padding-bottom: 20px;
}

.btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
}

.sideBarNav {
  overflow: hidden;
  marginbottom: 32px;
}

.sideBarNav:hover {
  overflow-y: auto;
}

.sideBarNav::-webkit-scrollbar {
  width: 0.3rem !important;
  height: 0.3rem !important;
  background-color: transparent;
}

.logoutItem {
  margin: 30px 0 10px 5px;
}

.guideItem {
  margin: 20px 0 0 0;
}

.sidebarToggle {
  position: absolute;
  top: 70px;
  right: -12px;
  z-index: 10;
  cursor: pointer;
}
