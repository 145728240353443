.wrapper {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  transform-origin: center;
  transition-duration: 100ms;
  transition-timing-function: ease-out;
  transform: translate(0, 0);
}

.cursor {
  cursor: pointer;
}

.small {
  height: 0.8rem;
  width: 0.8rem;
}

.normal {
  height: 1rem;
  min-width: 1rem;
}

.lite {
  min-width: 1.2rem;
  height: 1.2rem;
}

.medium {
  min-width: 1.4rem;
  height: 1.4rem;
}

.big {
  width: 1.6rem;
  height: 1.6rem;
}

.extra-big {
  width: 2rem;
  height: 2rem;
}

.huge {
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  min-height: 2.4rem;
}

.extra-huge {
  width: 3.3rem;
  height: 3.3rem;
}

.add {
  background-image: url("./images/add.svg");
}

.avatar {
  background-image: url("./images/avatar.svg");
}

.visible {
  background-image: url("./images/visible.svg");
}

.invisible-eye {
  background-image: url("./images/invisible-eye.svg");
}

.tick {
  background-image: url("./images/tick.svg");
}

.search {
  background-image: url("./images/search.svg");
}

.cross {
  background-image: url("./images/cross.svg");
}

.heart {
  background-image: url("./images/heart.svg");
}

.dropdown-arrow {
  background-image: url("./images/dropdown-arrow.svg");
}

.arrow-left {
  background-image: url("./images/arrow-left.svg");
}

.arrow-right {
  background-image: url("./images/arrow-right.svg");
}

.arrow-up {
  background-image: url("./images/arrowUp.svg");
}

.edit {
  background-image: url("./images/edit.svg");
}

.bin {
  background-image: url("./images/bin.svg");
}

.cancel {
  background-image: url("./images/cancel.svg");
}

.ok {
  background-image: url("./images/ok.svg");
}

.upload-image {
  background-image: url("./images/upload-image.svg");
}

.rotateRight90 {
  transform: rotate(90deg);
}

.rotate90 {
  transform: rotate(90deg);
}

.rotate180 {
  transform: rotate(180deg);
}

.blue {
  background-color: var(--fill-primary-light);
}

.right04 {
  margin-right: 0.4rem;
}

.right08 {
  margin-right: 0.8rem;
}

.left04 {
  margin-left: 0.4rem;
}

.left04 {
  margin-left: 0.4rem;
}
