.next,
.prev {
  div {
    margin: auto;
  }
}
.downloadAllIcon{
  text-align: center;
  margin-top: 5px;
  cursor: pointer;
}
.downloadAllIcon:hover{
  color:#f33;
}