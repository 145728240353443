.AskAQuestionContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   .askAQuestionBtn {
      margin: 70px auto 0 auto;
      cursor: pointer;
      font-size: 20px;
      width: 220px;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 20px;
      text-align: center;
      background-color: #f79633;
      color: #fff;
      font-weight: 500;
   }
}
