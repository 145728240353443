.root {
  padding: 0 40px 40px;
  min-height: 100vh;

  td,
  th {
    vertical-align: middle;
  }
}

.title {
  font-size: 32px;
  color: var(--fill-low-emphasis);
  margin-bottom: 15px;
}

.wrapper {
  min-width: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  background-color: var(--fill-base);
  border-radius: var(--border-radius-2);
  padding: 30px;
}

.addCategory {
  display: flex;

  div {
    margin: 0;
  }

  input {
    width: 250px;
  }

  button {
    margin-left: 10px;
  }
}

.loading {
  cursor: progress !important;
}
.categoryName{
  font-size: 24px;
  margin-top: 20px;
}
.uploadButton{
  align-items: center;
}
.uploadButtonLoad{
  cursor: progress;
}
.videoItem {
  display: flex;
  background-color: #f6f8fc;
  padding: 16px;
  width: fit-content;
  border-radius: 8px;
  align-items: center;
  margin-right: 10px;
  p {
    font-size: 24px;
    margin: 0 16px 0 5px;
  }
}
.videoItem:hover{
  background-color: #d3e3fd;
}
.addVideoItem{
  margin-top: 18px;
}
.playButton, .addVideoItem{
  cursor: pointer;
}
.videoModal div{
  background: none;
  box-shadow: none;
}

@media (max-width: 800px) {
  .root {
    padding: 0 10px 10px;
  }
}
