.root {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
  min-height: 56px;
  border-radius: var(--border-radius-1);
  transition: background-color var(--hover-transition) ease-in-out;
  // overflow: hidden;
  margin-bottom: 35px;

  &:focus-within {
    &.primary {
      background-color: var(--fill-base);
    }

    &.secondary {
      background-color: var(--elevation-3);
    }
  }
}

.input {
  width: 100%;
  height: 100%;
  min-height: 3.2rem;
  background-color: transparent;
  border: none;
  outline: none;
  touch-action: none;
  -webkit-touch-action: none;
  padding: 1.1rem 1.1rem 1.1rem 0;
  color: var(--fill-low-emphasis);
  font-size: 14px;

  &::placeholder {
    color: var(--fill-low-emphasis);
    opacity: 0.5;
  }

  &.disabled {
    background-color: var(--fill-disabled);
  }

  &[type="password"]::-ms-reveal,
  &[type="password"]::-ms-clear {
    display: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: var(--fill-low-emphasis) !important;
    font-size: 14px;
    -webkit-background-clip: text;
  }

  &:-webkit-autofill::first-line {
    font-size: 14px !important;
  }

  transition: background-color var(--hover-transition) ease-in-out;

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
  }
}
.root:focus  {
  border:2px solid red;
}

.passwordInput {
  width: 90%;
}

.primary {
  background-color: var(--fill-base);
  border: 1px solid var(--fill-border);
  border-radius: var(--border-radius-3);
}

.secondary {
  background-color: var(--elevation-2);

  &::placeholder {
    color: var(--fill-low-emphasis);
  }
}

.errorInput {
  border: 1px solid var(--fill-error);
}

.visibility {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}
.searchIcon {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.warning {
  display: flex;
  align-items: center;
}

.error {
  position: absolute;
  bottom: -25px;
  left: 5px;
  width: 100%;
  color: var(--fill-error-text);
  padding: 0.2rem;
  font-size: 1rem;
}

.activeError {
  display: none;
}

.overlay {
  width: 100%;
  height: 100%;
  min-height: 1.2rem;
  position: absolute;
  background-color: var(--fill-warning);
  opacity: 0.6;
  left: 0;
  top: 0;
  z-index: -1;
}

.noPadding,
.noPaddingRoot {
  padding: 0;
}

.noPaddingRoot {
  padding-left: 0.2rem;
}

.borderLeft:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 1.1rem;
  width: 0.05rem;
  transform: translateY(-50%);
  background-color: var(--fill-low-emphasis);
  opacity: 0.4;
}

.activeInput {
  background-color: var(--elevation-2);
}

.activeInput.secondary {
  background-color: var(--elevation-3);
}

.capitalize {
  text-transform: capitalize;
}

.small {
  min-height: 1.8rem;
  padding: 0.2rem 0.4rem;
  font-size: 1rem;
  line-height: 1.4;
  min-width: 8rem;

  .input {
    min-height: 1.8rem;
    padding-left: 0.8rem;
  }
}

.big {
  display: flex;
  align-items: center;
  min-height: 3.2rem;
  padding: 0.2rem 0.4rem;
  font-size: 1rem;
  line-height: 1.4;
  min-width: 8rem;

  .input {
    min-height: 1.8rem;
    padding: 0;
    padding-left: 0.8rem;
  }
}

.micro {
  min-height: 1.8rem;
  height: 1.8rem;
  padding: 0 0.8rem;
  font-size: 1rem;
  line-height: 1.4;
  min-width: 6rem;
  margin-top: 0;
  margin-left: 0;
  width: 100%;

  .input {
    min-height: 1.8rem;
    height: 1.8rem;
    padding: 0;
  }
}

.extraSmall {
  min-height: 2rem;
  height: 2rem;
  padding: 0 0.4rem;
  font-size: 1rem;
  line-height: 1.4;
  min-width: 6rem;
  margin-top: 0 !important;
  margin-left: 0 !important;
  width: 100%;

  .input {
    min-height: 2rem;
    height: 2rem;
    padding: 0;
  }
}

.transparent,
.transparentSecondary {
  background-color: transparent;
}

input:-webkit-autofill::first-line {
  font-size: 1rem;
}

.list {
  position: absolute;
  top: 4rem;
  left: 0;
  width: 70%;
  max-height: 20rem;
  overflow-y: auto;
  background-color: var(--elevation-2);
  box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.35);
  border-radius: var(--border-radius-2);
}

.listItem {
  padding: 1rem;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: var(--elevation-3);
  }

  &:active {
    background-color: var(--elevation-4);
  }
}

.multiple {
  padding-right: 1.4rem;
  overflow: inherit;
  z-index: 1;
}

.listItemAdd {
  display: flex;
  align-items: center;
  color: var(--fill-primary-light-core);

  div {
    margin-right: 0.4rem;
  }
}

.noMatchesFound {
  padding: 1rem;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: var(--fill-low-emphasis);
}

.secondaryVariant {
  padding: 0 1.6rem;

  .input {
    padding: 1rem 1rem 1rem 0;
    font-size: 1.4rem;
    color: var(--neutral-black);

    &::placeholder {
      color: var(--neutral-light-grey);
    }
  }

  .activeInput {
    background-color: transparent;
  }
}

.label {
  min-height: 33px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 16px;
  color: var(--fill-low-emphasis);
  display: block;
}

.disabled {
  background-color: #f5f5f5;
  border-color: #dfe5e9;
}

.requiredSpan {
  color: var(--fill-error);
  font-size: 22px;
}
