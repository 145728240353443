@import "./breakpoints";

html {
  min-height: 100%;
  line-height: 1.2em;
  font-size: calc(14px * var(--multiplier));
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-primary);
  background-color: var(--fill-base);
  color: var(--fill-low-emphasis);
}

section {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  font-size: 1rem;
}

* {
  scrollbar-width: thin;
}

h1 {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.17;
  letter-spacing: normal;
  color: var(--fill-high-emphasis);
}

*::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
  background-color: #5c5c5c;
  scrollbar-width: 0.8rem;
}

*::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: var(--fill-high-emphasis);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--fill-border);
  cursor: pointer;
  border-radius: 2rem;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--fill-border);
}

*::-webkit-scrollbar-corner {
  background-color: var(--fill-high-emphasis);
}
