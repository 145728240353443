.Menu {
  background: #272726;
  position: sticky;
  top: 0;
}

.MobileMenu,
.MenuMobileUl {
  display: none;
}

.MenuUl {
  display: flex;
  justify-content: space-between;

  li {
    padding: 33px 0 8px 0;
    border-bottom: 7px solid #272726;
  }

  li:hover {
    border-color: #ffb801;
  }
}

.MenuUl,
.MenuMobileUl {
  li {
    cursor: pointer;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.75px;
    text-transform: uppercase;
  }

  li:hover {
    border-color: #ffb801;
  }
}

.scrollTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}

.scrollTopHidden {
  display: none;
}

@media (max-width: 992px) {
  .MenuMobileUl {
    display: block;
    text-align: center;

    li {
      padding: 10px;
      border: none;
    }
  }
  .MenuUl {
    display: none !important;
  }
  .MobileMenu {
    display: block;
  }
  .MobileMenu {
    display: flex;
    justify-content: space-between;
  }
  .MenuHeader {
    max-width: 100%;
    padding: 10px;
  }
}
