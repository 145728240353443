.questionsContainerSection {
  margin-top: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 45px;
  .questionBtn {
    margin: 0 auto;
    cursor: pointer;
    font-size: 20px;
    width: 220px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    text-align: center;
    background-color: #f79633;
    color: #ffff;
    font-weight: 500;
  }
  .questionsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    //border: 1px solid;
    border-radius: 10px;
    background-color: #e9ecef;
    padding-bottom: 20px;
    .questionContent {
      width: 500px;
      height: auto;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .questionNotFound {
        font-size: 18px;
      }
      .UserProfile {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
      }
      .questionText {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-left: 20px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 19px;
        justify-content: flex-start;
        word-break: normal;
        border-radius: 5px;
        font-weight: bold;
        text-align: justify;
      }
    }
  }
}
.questionButtons {
  width: 700px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
  background-color: #f2f2f2;
  .questionBtnEdit {
    color: blue;
  }
  .questionBtnDelete {
    color: #ff3333;
  }
  .questionBtnReply {
    color: green;
  }
  p {
    font-size: 15px;
  }
}
.answerContainer {
  background-color: #dee2e6;
  width: 96%;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;

  .answerText {
    font-size: 19px;
    margin-top: 10px;
  }
}
