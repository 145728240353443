.clearMenuSelect {
  min-width: 7rem;
  padding: 0.4rem 0;
  background-color: var(--elevation-6);
}

.menuItem {
  font-size: 1rem;
  color: var(--fill-medium-emphasis);
  padding: 0.4rem 0.8rem;
  background-color: var(--elevation-6);
  transition: background-color 0.2s, color 0.2s;
  cursor: pointer;
  font-weight: 400;
  display: flex;
  align-items: center;
  line-height: normal;

  &:hover {
    background-color: var(--elevation-20);
    color: var(--fill-high-emphasis);

    & .iconWrapper {
      opacity: 1;
    }
  }

  // &:first-child {
  //   border-radius: var(--border-radius-2) var(--border-radius-2) 0 0;
  // }
  // &:last-child {
  //   border-radius: 0 0 var(--border-radius-2) var(--border-radius-2);
  // }

  &.hovered {
    background-color: var(--elevation-20);
    color: var(--fill-high-emphasis);
    & .iconWrapper {
      opacity: 1;
    }
  }
}

.small {
  max-height: 20rem;
  overflow-y: auto;
}

.menuActiveItem {
  background-color: var(--elevation-20);
  color: var(--fill-high-emphasis);
}

.dark,
.light {
  padding: 0.4rem 0;

  .menuItem {
    color: var(--fill-high-emphasis);
    background-color: var(--elevation-2);

    &:hover {
      background-color: var(--elevation-3);
    }

    &:first-child {
      border-radius: 0;
    }
    &:last-child {
      border-radius: 0;
    }
  }

  .menuActiveItem {
    background-color: var(--elevation-6);
  }
}
.light {
  .menuItem {
    color: var(--fill-medium-emphasis);
    background-color: var(--elevation-6);
  }
  .menuActiveItem {
    background-color: var(--elevation-3);
  }
}

.iconWrapper {
  margin-right: 0.4rem;
  opacity: 0.6;
  transition: opacity 0.2s;

  &.iconActive {
    opacity: 1;
  }
}
