.returnsSection {
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   margin-top: 180px;
   h1 {
      font-size: 48px;
      color: #000;
      margin-bottom: 26px;
   }
   p {
      font-size: 20px;
      color: #000;
      margin-bottom: 60px;
      text-align: center;
   }
   .returnsConditions {
      display: flex;
      justify-content: center;
      gap: 45px;

      text-align: center;
      .returnsConditation {
         width: 500px;

         li {
            color: #000;
            font-size: 20px;
            list-style: none;
         }
         .containerTextDot {
            display: flex;
            flex-direction: column;
            .xx{
               display: flex;
               
            }
           
         }
         .containerText {
            text-align: left;
            margin-bottom: 20px;
         }
      }
   }
}


@media (max-width: 600px) {
   .returnsSection {
      margin-top: 100px;
      .returnsConditions{
         flex-direction: column;
         
        
      }
   }
   
}