.AdvantageHeader {
  h1 {
    font-style: normal;
    color: black;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
  }

  p {
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }
}

.Vector4,
.mobileVector {
  width: 100%;
}

.mobileVector {
  display: none;
}

.AdvantageImgWild,
.AdvantageImgOzon,
.AdvantageImgYandex {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    width: 100%;
    height: auto;
  }

  p {
    font-weight: 400;
  }
}

.AdvantageImgYandex {
  img {
    height: 74px;
    width: auto !important;
  }
}

@media (max-width: 576px) {
  .AdvantageHeader {
    margin-bottom: 80px;

    h1 {
      font-size: 32px;
      margin-bottom: 50px;
    }

    p {
      font-size: 15px;
    }
  }
  .AdvantageImgWild,
  .AdvantageImgOzon,
  .AdvantageImgYandex {
    p {
      margin-top: 16px;
      margin-bottom: 50px;
      font-size: 15px;
    }
  }
}

@media (min-width: 577px) {
  .AdvantageHeader {
    margin-bottom: 111px;

    h1 {
      font-size: 48px;
      margin-bottom: 63px;
    }

    p {
      font-size: 20px;
    }
  }
  .AdvantageImgWild,
  .AdvantageImgOzon,
  .AdvantageImgYandex {
    p {
      margin-top: 16px;
      margin-bottom: 50px;
      font-size: 20px;
    }
  }
}

@media (min-width: 992px) {
  .AdvantageInfo {
    display: flex;
    gap: 48px;
  }
}
