.modalWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 2;
  transition: opacity linear 0.15s;
  z-index: 2000;
  display: flex;
  flex-direction: column;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    opacity: 2;
  }

  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 90vh;
    opacity: 2;
  }
}

.modal {
  position: relative;
  z-index: 1050;
  width: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;

  &.expandedHeight {
    height: 100%;
  }
}

.fade-in {
  opacity: 1;
  transition: opacity linear 0.15s;
}

.fade-out {
  opacity: 0;
  transition: opacity linear 0.15s;
}

.background {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1040;
  display: block;
  left: 0;
  right: 0;
  outline: 0;
  height: 100vh;
}

.boxDialog {
  width: 100%;
  background-color: var(--fill-base);
  border-radius: var(--border-radius-2);
  box-shadow: 0 0 1rem 0.1rem rgba(25, 25, 25, 0.25);
  // overflow-y: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100%;
  opacity: 2;
}
