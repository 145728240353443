.sendQuestionContainer {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 30px;
  text-align: center;
  display: flex;
  background-color: #e9ecef;
  border-radius: 10px;
  textarea {
    width: 320px;
    padding: 10px;
    border: 1px solid;
    margin-right: 20px;
    border-radius: 20px;
    outline: none;
  }
}
