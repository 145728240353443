.salesDynamicsSection {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-top: 130px;
   gap: 50px;
   margin-bottom: 120px;
   padding: 0  !important;
   h1 {
      font-size: 48px;
      color: #000;
      text-align: center;
   }
   p {
      font-size: 20px;
      color: #000;
      max-width: 810px;
      line-height: normal;
      margin-bottom: 20px;
   }
}

@media (max-width: 389px) {
   .salesDynamicsSection {
      gap: 0px;
      margin-bottom: 80px;
      h1 {
         font-size: 32px;
      }
      p {
         font-size: 15px;
         margin-top: 10px;
      }
   }
}

@media (max-width: 550px) {
   .salesDynamicsSection {
     
      p {
         text-align: center;
         margin-top: 30px;
         margin-bottom: 0;
      }
   }
}
