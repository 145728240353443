.root {
  position: relative;
  min-height: 100vh;
}
.wallpaper {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.content {
  max-width: 540px;
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.window {
  width: 100%;
  background-color: var(--fill-base);
  padding: 45px 60px;
  border-radius: var(--border-radius-3);
}
.title {
  font-size: 32px;
  text-align: center;
  margin-bottom: 30px;
  color: var(--fill-low-emphasis);
}
.subtitle {
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
  color: var(--fill-medium-emphasis);
}
.back {
  margin-bottom: var(--spacing-m);
}
.options {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.divider {
  height: 15px;
  width: 1px;
  background-color: var(--fill-low-emphasis);
  margin: 0 20px;
}
.link {
  color: var(--fill-primary);
}
