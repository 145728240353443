.root {
  position: relative;
}

.wallpaper {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.content {
  max-width: 1040px;
  margin: auto;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.window {
  width: 100%;
  background-color: var(--fill-base);
  padding: 45px 60px;
  border-radius: var(--border-radius-3);
}

.subtitle {
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
  color: var(--fill-medium-emphasis);
}

.select {
  height: calc(30vh + 70px);
  position: relative;

  :global {
    .react-select__menu {
      height: 30vh;
    }

    .react-select__indicators {
      display: none;
    }

    .react-select__menu-list {
      height: 30vh;
      max-height: 30vh;
    }

    .react-select__control {
      padding-left: 30px;
    }

    .react-select__input-container {
      color: black;
      padding-left: 0;

      input {
        height: 100%;
      }
    }
  }
}

.icon {
  position: absolute;
  top: 14px;
  left: 15px;
  z-index: 2;
}

.option {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
  min-height: 20px;
}

.back {
  margin-bottom: var(--spacing-m);
  margin-top: var(--spacing-m);
}

.forgot {
  text-align: center;
  color: var(--fill-low-emphasis);
  display: block;
  margin: 30px 0 0;
}

.options {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.divider {
  height: 15px;
  width: 1px;
  background-color: var(--fill-low-emphasis);
  margin: 0 20px;
}

.list {
  margin-top: 30px;

  .item {
    color: var(--fill-low-emphasis);
    margin-bottom: 5px;
    display: flex;
  }
}

.welcomeTitle {
  text-align: center;

  img {
    width: 300px;
    margin: -70px 0;
  }
}

.title {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: var(--fill-low-emphasis);
}

.normalCheckbox {
  margin-top: 10px;

  span {
    color: black;
  }
}
